import { slugify } from '../../../app/utils/utils.common';
import {
  SocialBadge,
  FreeAndroid,
  FreeIphone,
  BasicAndroid,
  BasicIphone,
  AdvancedAndroid,
  AdvancedIphone,
  PremiumAndroid,
  PremiumIphone,
  PremiumWeb,
  AdvancedWeb,
  BasicWeb,
  FreeWeb,
} from '../../../assets/images';
import {
  AndroidSubscriberBadge,
  EarlySubscriberAndroid1,
  EarlySubscriberAndroid2,
  EarlySubscriberAndroid3,
  EarlySubscriberIOS1,
  EarlySubscriberIOS2,
  EarlySubscriberIOS3,
  IOSSubscriberBadge,
  WebSubscriberBadge,
  ExtensionGiftCardGold1,
  ExtensionGiftCardGold2,
  ExtensionGiftCardGold3,
  ExtensionGiftCardGold4,
  ExtensionGiftCardSilver1,
  ExtensionGiftCardSilver2,
  ExtensionGiftCardSilver3,
  ExtensionGiftCardSilver4,
  EarlyNotSubscriberIOS1,
  EarlyNotSubscriberIOS2,
  EarlyNotSubscriberIOS3,
  EarlyNotSubscriberAndroid1,
  EarlyNotSubscriberAndroid2,
  EarlyNotSubscriberAndroid3,
  PreLaunchMemberBadge,
  EarlyAdopter,
  QuickStarterBadge,
  OneStarGiftcardInviter,
  TwoStarGiftcardInviter,
  QuickStartGuide,
  FoundingMemberBadge,
  EmailSubscriberBadge,
  EmailSignupBadge,
  EmailInviteBadge,
  TextSubscriberBadge,
  TextSignupBadge,
  TextInviteBadge,
  QrCodeSubscriberBadge,
  QrCodeSignupBadge,
  QrCodeInviteBadge,
  DMSubscriberBadge,
  DMSignupBadge,
  DMInviteBadge,
} from '../../../assets/svg';
import { apiV2Instance as axios } from '../../lib/api-v2.client';

export enum Badges {
  PRELAUNCH = 'prelaunch',
  FOUNDING_MEMBER = 'founding-member',
  SOCIAL_MEDIA_CONNECTION = 'social-media-connection',
  SUBSCRIBER_IOS = 'subscriber-ios',
  SUBSCRIBER_ANDROID = 'subscriber-android',
  SUBSCRIBER_WEB = 'subscriber-web',
  BETA_TESTER = 'beta-tester',
  EARLY_SUBSCRIBER_IOS = 'early-subscriber-ios',
  EARLY_SUBSCRIBER_ANDROID = 'early-subscriber-android',
  EARLY_ADOPTER = 'early-adopter',
  QUICKSTART_GUIDE = 'quickstart-guide',
  EXTENSION_GIFT_CARDS = 'giftcard-subscriber-premium',
  STAR_GIFTCARD_INVITER = 'star-giftcard-inviter',
  EARLY_SUBSCRIBER_WEB = 'early-subscriber-web',
  FEEDBACK_BADGE = 'feedbackbadge',
  QUICK_STARTER = 'quickstarter',
  INVITE_WIZARD = 'invite-wizard',
  EMAIL_INVITER = 'email-inviter',
  TEXT_INVITER = 'text-inviter',
  QR_CODE_INVITER = 'qr-code-inviter',
  DM_INVITER = 'dm-inviter',
}

export interface IBadge {
  badgeId: string;
  userId: string;
  badge: {
    _id: string;
    code: string;
    meta: any;
    name: string;
    description: string;
  };
  meta?: any;
}

export const BADGES_SOURCE: any = {
  [Badges.SUBSCRIBER_ANDROID]: {
    pro: PremiumAndroid,
    advance: AdvancedAndroid,
    basic: BasicAndroid,
    trial: FreeAndroid,
    free: AndroidSubscriberBadge,
  },
  [Badges.SUBSCRIBER_IOS]: {
    pro: PremiumIphone,
    advance: AdvancedIphone,
    basic: BasicIphone,
    trial: FreeIphone,
    free: IOSSubscriberBadge,
  },
  [Badges.SUBSCRIBER_WEB]: {
    pro: PremiumWeb,
    advance: AdvancedWeb,
    basic: BasicWeb,
    trial: FreeWeb,
    free: WebSubscriberBadge,
  },
  [Badges.EARLY_SUBSCRIBER_IOS]: {
    gold: {
      1: EarlySubscriberIOS1,
      2: EarlySubscriberIOS2,
      3: EarlySubscriberIOS3,
    },
    silver: {
      1: EarlyNotSubscriberIOS1,
      2: EarlyNotSubscriberIOS2,
      3: EarlyNotSubscriberIOS3,
    },
  },
  [Badges.EARLY_SUBSCRIBER_ANDROID]: {
    gold: {
      1: EarlySubscriberAndroid1,
      2: EarlySubscriberAndroid2,
      3: EarlySubscriberAndroid3,
    },
    silver: {
      1: EarlyNotSubscriberAndroid1,
      2: EarlyNotSubscriberAndroid2,
      3: EarlyNotSubscriberAndroid3,
    },
  },
  [Badges.EXTENSION_GIFT_CARDS]: {
    gold: {
      1: ExtensionGiftCardGold1,
      2: ExtensionGiftCardGold2,
      3: ExtensionGiftCardGold3,
      4: ExtensionGiftCardGold4,
    },
    silver: {
      1: ExtensionGiftCardSilver1,
      2: ExtensionGiftCardSilver2,
      3: ExtensionGiftCardSilver3,
      4: ExtensionGiftCardSilver4,
    },
  },
  [Badges.STAR_GIFTCARD_INVITER]: {
    gold: {
      1: OneStarGiftcardInviter,
      2: TwoStarGiftcardInviter,
    },
    silver: {},
  },
  [Badges.EMAIL_INVITER]: {
    subscriber: EmailSubscriberBadge,
    signup: EmailSignupBadge,
    invite: EmailInviteBadge,
  },
  [Badges.TEXT_INVITER]: {
    subscriber: TextSubscriberBadge,
    signup: TextSignupBadge,
    invite: TextInviteBadge,
  },
  [Badges.QR_CODE_INVITER]: {
    subscriber: QrCodeSubscriberBadge,
    signup: QrCodeSignupBadge,
    invite: QrCodeInviteBadge,
  },
  [Badges.DM_INVITER]: {
    subscriber: DMSubscriberBadge,
    signup: DMSignupBadge,
    invite: DMInviteBadge,
  },
};

const MAP_BADGE_CODE_TO_ICONS: any = {
  'prelaunch-gold-badge': PreLaunchMemberBadge,
  'day-1-android-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_ANDROID].gold[1],
  'day-2-android-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_ANDROID].gold[2],
  'day-3-android-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_ANDROID].gold[3],
  'day-1-iphone-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_IOS].gold[1],
  'day-2-iphone-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_IOS].gold[2],
  'day-3-iphone-early-subscriber-gold-badge':
    BADGES_SOURCE[Badges.EARLY_SUBSCRIBER_IOS].gold[3],
  '1-star-premium-gift-card-inviter-gold-badge':
    BADGES_SOURCE[Badges.STAR_GIFTCARD_INVITER].gold[1],
  '2-star-premium-gift-card-inviter-gold-badge':
    BADGES_SOURCE[Badges.STAR_GIFTCARD_INVITER].gold[2],
  'quickstart-guide-completion-badge': QuickStartGuide,
  'quickstarter-gold-badge': QuickStarterBadge,
  'early-adopter-gold-badge': EarlyAdopter,
  'founding-member-gold-badge': FoundingMemberBadge,
  'social-badge': SocialBadge,
  'android-premium-subscription-badge':
    BADGES_SOURCE[Badges.SUBSCRIBER_ANDROID].pro,
  'android-advanced-subscription-badge':
    BADGES_SOURCE[Badges.SUBSCRIBER_ANDROID].advance,
  'android-basic-subscription-badge':
    BADGES_SOURCE[Badges.SUBSCRIBER_ANDROID].basic,
  'iphone-premium-subscription-badge': BADGES_SOURCE[Badges.SUBSCRIBER_IOS].pro,
  'iphone-advanced-subscription-badge':
    BADGES_SOURCE[Badges.SUBSCRIBER_IOS].advance,
  'iphone-basic-subscription-badge': BADGES_SOURCE[Badges.SUBSCRIBER_IOS].basic,
  'week-1-extension-gift-card-gold-badge':
    BADGES_SOURCE[Badges.EXTENSION_GIFT_CARDS].gold[1],
  'web-premium-subscription-badge': BADGES_SOURCE[Badges.SUBSCRIBER_WEB].pro,
  'web-advanced-subscription-badge': BADGES_SOURCE[Badges.SUBSCRIBER_WEB].advance,
  'web-basic-subscription-badge': BADGES_SOURCE[Badges.SUBSCRIBER_WEB].basic,
};

export class BadgeService {
  hasPrelaunchBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.PRELAUNCH;
      }),
    );
  }

  hasFoundingMemberBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.FOUNDING_MEMBER;
      }),
    );
  }

  hasSocialConnectionBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.SOCIAL_MEDIA_CONNECTION;
      }),
    );
  }

  hasAndroidSubscriberBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.SUBSCRIBER_ANDROID;
      }),
    );
  }

  hasIOSSubscriberBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.SUBSCRIBER_IOS;
      }),
    );
  }

  hasWebSubscriberBadge(badges: IBadge[]): boolean {
    return Boolean(
      badges.find(badge => {
        return badge?.badge?.code === Badges.SUBSCRIBER_WEB;
      }),
    );
  }

  getFreeTrialDayBadge(badges: IBadge[], code: string): any {
    const badge = badges.find(badge => {
      return badge?.badge?.code === code;
    });
    return this.getFreeTrialDayForParticularBadge(code, badge);
  }

  async getHighlightedBadges(): Promise<any[]> {
    const res = await axios.get(`/api/i/badges?highlighted=true`);
    return res?.data || [];
  }

  getBadgeImage(name: string): any {
    if (!name) {
      return WebSubscriberBadge;
    }
    const badgeCode = slugify(name);
    if (!MAP_BADGE_CODE_TO_ICONS[badgeCode]) {
      console.log('BadgeNotFound for ', badgeCode);
    }
    return MAP_BADGE_CODE_TO_ICONS[badgeCode] || WebSubscriberBadge;
  }

  getFreeTrialDayForParticularBadge(code: string, badge?: IBadge): any {
    if (!code || code === Badges.SUBSCRIBER_WEB) {
      return { src: null, freeTrialDay: null };
    }
    if (badge) {
      if (
        code === Badges.EARLY_SUBSCRIBER_IOS ||
        code === Badges.EARLY_SUBSCRIBER_ANDROID
      ) {
        return {
          src: BADGES_SOURCE?.[code][badge?.meta?.color ?? 'gold'][
            badge?.meta?.freeTrialDay
          ],
          freeTrialDay: null,
        };
      }
      if (code === Badges.EXTENSION_GIFT_CARDS && badge?.meta?.color) {
        const weekCount = badge?.meta?.week <= 4 ? badge?.meta?.week : 4;
        return {
          src: BADGES_SOURCE?.[code][badge?.meta?.color][weekCount],
          freeTrialDay: null,
        };
      }
      if (code === Badges.SUBSCRIBER_ANDROID) {
        if (badge?.meta?.subscriptionType === 'gifted') {
          return { src: null, freeTrialDay: null };
        }
      }
      if (badge?.meta?.subscriptionType) {
        return {
          src:
            BADGES_SOURCE[code][badge.meta.subscriptionType] ||
            BADGES_SOURCE[code].free,
          freeTrialDay:
            badge.meta.freeTrialDay > 0 && badge.meta.freeTrialDay < 8
              ? badge.meta.freeTrialDay
              : null,
        };
      }
      return { src: BADGES_SOURCE?.[code]?.free, freeTrialDay: null };
    }
    return { src: null, freeTrialDay: null };
  }
}
