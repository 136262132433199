import { apiV2Instance } from '../../lib/api-v2.client';
import {
  IUserData,
  IUserBadgesData,
  ISubscriptionData,
  IConnectionData,
} from './alert.interface';

export class AlertService {
  getUserAlert(duration: string): Promise<IUserData> {
    return apiV2Instance
      .get(`api/v2/user/alerts?duration=${duration}`, {
        silent: true,
      })
      .then(response => response.data?.data);
  }

  getUserBadgesAlert(duration: string): Promise<IUserBadgesData> {
    return apiV2Instance
      .get(`api/i/user-badges/alerts?duration=${duration}`, {
        silent: true,
      })
      .then(response => response.data?.data);
  }

  getSubscriptionAlert(duration: string): Promise<ISubscriptionData> {
    return apiV2Instance
      .get(`api/subscription/alerts?duration=${duration}`, {
        silent: true,
      })
      .then(response => response.data?.data);
  }

  getConnectionAlert(duration: string): Promise<IConnectionData> {
    return apiV2Instance
      .get(`api/connections/alerts?duration=${duration}`, {
        silent: true,
      })
      .then(response => response.data?.data);
  }
}
