import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface DeprecatedThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#007aff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(0, 0, 0, 0.5)',
            color: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: '8px',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '8px',
          textTransform: 'capitalize',
        },
      },
    },
  },
});

export { theme };
