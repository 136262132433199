/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import posthog from 'posthog-js';
import storage from 'redux-persist/lib/storage';
import {
  IInviteShareData,
  IUserData,
  IRedirectURI,
} from '../../common/services/user/user-data.interface';
import { IUserReferrersReferrals } from '../../common/services/user/user-referrerReferrals.interface';

export interface CommonState {
  showLoader: boolean;
  auth?: {
    token?: string;
  };
  redirectURI?: {
    url?: string;
    refCode?: string;
  };
  user?: IUserData;
  referrerReferrals?: IUserReferrersReferrals;
  inviteShare?: IInviteShareData;
}

const initialState: CommonState = {
  showLoader: false,
  auth: undefined,
};

const commonSlice = createSlice({
  initialState,
  name: 'common',
  reducers: {
    setAuth: (
      state,
      action?: PayloadAction<{
        token: string;
      }>,
    ) => {
      state.auth = action?.payload;
    },
    logout: state => {
      storage.removeItem('persist:root');
      localStorage.removeItem('referringChannelId');
      localStorage.removeItem('channelId');
      state.auth = undefined;
      state.user = undefined;
      state.redirectURI = undefined;
      window.location.href = window.location.origin;
      try {
        posthog?.reset?.();
      } catch (e) {
        console.error(e);
      }
    },
    setShowLoader: (state, action: PayloadAction<boolean>) => {
      state.showLoader = action.payload;
    },
    setRedirectURI: (state, action: PayloadAction<IRedirectURI>) => {
      state.redirectURI = action.payload;
    },
    setUserData: (state, action: PayloadAction<IUserData | undefined>) => {
      try {
        posthog?.identify(action.payload?._id);
        posthog?.people?.set?.({ email: action.payload?._id });
      } catch (e) {
        console.error(e);
      }
      state.user = action.payload;
    },
    setReferrerReferrals: (
      state,
      action: PayloadAction<IUserReferrersReferrals>,
    ) => {
      state.referrerReferrals = action.payload;
    },
    setInviteShareMessage: (state, action: PayloadAction<IInviteShareData>) => {
      state.inviteShare = action.payload;
    },
  },
});

const commonReducer = commonSlice.reducer;

export const {
  setAuth,
  setShowLoader,
  setUserData,
  setRedirectURI,
  setReferrerReferrals,
  logout,
  setInviteShareMessage,
} = commonSlice.actions;

export default commonReducer;
