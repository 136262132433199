/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUserData } from '../user/user-data.interface';
import { apiV2Instance } from '../../lib/api-v2.client';

interface IUserAuthResponse {
  status: 'success' | 'error';
  message: string;
  error?: string;
  token: string;
  'un-read': number;
  result: {
    diffDays: number;
    isRegistered: boolean;
    user: {
      _id: string;
    } & Omit<IUserData, 'id'>;
  };
}

export interface IUserAuthData {
  token: string;
  user: {
    diffDays: number;
    isRegistered: boolean;
  } & IUserData;
}

export class AuthService {
  login(
    code: string,
    redirectUrl: string,
    refCode: string,
  ): Promise<IUserAuthData> {
    return apiV2Instance
      .post('/auth/login', {
        code,
        redirect_uri: redirectUrl,
        platformType: 'web',
        refCode,
      })
      .then((response: { data: IUserAuthResponse }) => {
        const { data } = response;
        const { result, token, status, message } = data;
        if (status === 'error') {
          throw new Error(message);
        }
        const { user, ...restResult } = result;
        const { _id: id, userStateFlags, ...restUser } = user;
        userStateFlags.sort((a, b) => (a.stateCode < b.stateCode ? -1 : 1));
        return {
          user: {
            id,
            userStateFlags,
            ...restResult,
            ...restUser,
          },
          token,
        };
      })
      .finally(() => {
        localStorage.removeItem('redirect_uri');
      });
  }

  logout() {
    return apiV2Instance.post('/auth/logout').then(response => response.data);
  }
}
