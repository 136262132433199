import React, { useState, useCallback } from 'react';

export enum AlertVariant {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface AlertContextInterface {
  variant: AlertVariant;
  message: string;
  showAlert: (message: string, variant?: AlertVariant) => void;
}

export const AlertContext = React.createContext<AlertContextInterface>({
  variant: AlertVariant.INFO,
  message: '',
  showAlert: () => {},
});

export default function AlertProvider({ children }: any) {
  const [alert, setAlert] = useState({
    variant: AlertVariant.INFO,
    message: '',
  });

  const removeAlert = () => {
    setAlert({ ...alert, message: '' });
  };

  const showAlert = (message: string, variant: AlertVariant) => {
    setAlert({ message, variant });
    setTimeout(removeAlert, 5000);
  };

  const contextValue: AlertContextInterface = {
    message: alert.message,
    variant: alert.variant,
    showAlert: useCallback(
      (message, variant = alert.variant) => showAlert(message, variant),
      [],
    ),
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
}
