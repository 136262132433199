/* eslint-disable no-console */
import axios, { AxiosError } from 'axios';
import ENVIRONMENTS from '../../environments';
import { setShowLoader, logout } from '../../store/common/common.reducer';
import { store } from '../../store/create-store';

const apiV2Instance = axios.create({
  baseURL: ENVIRONMENTS.API_BASE_URL_V2,
});

apiV2Instance.interceptors.request.use(
  config => {
    const params = new URLSearchParams(window.location.search);
    if (!config.silent) store.dispatch(setShowLoader(true));
    const { common } = store.getState();
    const { auth = {} } = common;
    const { token } = auth;
    if (token || params.get('token')) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token || params.get('token')}`,
      };
    } else {
      console.log('no token', config.url);
    }
    return config;
  },
  (error: AxiosError) => {
    console.error(error);
    store.dispatch(setShowLoader(false));
    return Promise.reject(error.toJSON());
  },
);

apiV2Instance.interceptors.response.use(
  response => {
    store.dispatch(setShowLoader(false));
    return response;
  },
  (error: AxiosError) => {
    console.error(error);
    store.dispatch(setShowLoader(false));
    if (error.response?.status === 401) {
      store.dispatch(logout());
      return Promise.resolve(error.response);
    }
    return Promise.reject(error.response);
  },
);

export { apiV2Instance };
